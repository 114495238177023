@use '@finastra/angular-theme' as fds;
@use "sass:map";

$light-grey: #f1efef;
$grey: #cecece;
$dark-grey: #6d6d6d;
$pink:#e40046;
$white: #ffffff;

$C100-dark: #2D2D2D;
$C100: fds.$uxg-charcoal;
$C75: #707070;
$C50: #a0a0a0;
$C25: #cfcfcf;
$C10: #ebebeb;
$C05: #f5f5f5;
$BgDef: #fafafa;

$W100: white;

$V200: map.get(fds.$uxg-violet, 900);
$V175: map.get(fds.$uxg-violet, 800);
$V150: map.get(fds.$uxg-violet, 700);
$V125: map.get(fds.$uxg-violet, 600);
$V100: map.get(fds.$uxg-violet, 500);
$V75: map.get(fds.$uxg-violet, 400);
$V50: map.get(fds.$uxg-violet, 300);
$V25: map.get(fds.$uxg-violet, 200);
$V10: map.get(fds.$uxg-violet, 100);
$V05: map.get(fds.$uxg-violet, 50);

$F200: map.get(fds.$uxg-fuchsia, 900);
$F175: map.get(fds.$uxg-fuchsia, 800);
$F150: map.get(fds.$uxg-fuchsia, 700);
$F125: map.get(fds.$uxg-fuchsia, 600);
$F100: map.get(fds.$uxg-fuchsia, 500);
$F75: map.get(fds.$uxg-fuchsia, 400);
$F50: map.get(fds.$uxg-fuchsia, 300);
$F25: map.get(fds.$uxg-fuchsia, 200);
$F10: map.get(fds.$uxg-fuchsia, 100);
$F05: map.get(fds.$uxg-fuchsia, 50);

$R100-dark: map.get(fds.$uxg-crimson, '100-dark');
$R100: map.get(fds.$uxg-crimson, 100);
$R75: map.get(fds.$uxg-crimson, 75);
$R50: map.get(fds.$uxg-crimson, 50);
$R25: map.get(fds.$uxg-crimson, 25);
$R10: #ffe6ee;

$A100-dark: map.get(fds.$uxg-amber, '100-dark');
$A100: map.get(fds.$uxg-amber, 100);
$A75: map.get(fds.$uxg-amber, 75);
$A50: map.get(fds.$uxg-amber, 50);
$A25: map.get(fds.$uxg-amber, 25);

$Y100-dark: map.get(fds.$uxg-gold, '100-dark');
$Y100: map.get(fds.$uxg-gold, 100);
$Y75: map.get(fds.$uxg-gold, 75);
$Y50: map.get(fds.$uxg-gold, 50);
$Y25: map.get(fds.$uxg-gold, 25);
$Y10: #fcf1d9;

$L100-dark: map.get(fds.$uxg-lime, '100-dark');
$L100: map.get(fds.$uxg-lime, 100);
$L75: map.get(fds.$uxg-lime, 75);
$L50: map.get(fds.$uxg-lime, 50);
$L25: map.get(fds.$uxg-lime, 25);

$G100-dark: map.get(fds.$uxg-grass, '100-dark');
$G100: map.get(fds.$uxg-grass, 100);
$G75: map.get(fds.$uxg-grass, 75);
$G50: map.get(fds.$uxg-grass, 50);
$G25: map.get(fds.$uxg-grass, 25);
$G10: #ecfcf0;

$B100-dark: map.get(fds.$uxg-ocean, '100-dark');
$B100: map.get(fds.$uxg-ocean, 100);
$B75: map.get(fds.$uxg-ocean, 75);
$B50: map.get(fds.$uxg-ocean, 50);
$B25: map.get(fds.$uxg-ocean, 25);
$B10: #e2f9ff;

$dh-shade-color-48: rgba(207, 212, 214, 1);

$primary: $V100;
$C10: #ebebeb;

$base-font-family: "Noto Sans";
$icons-font-family: "Material Design Icons";

$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;

$scrollbar-width: 25px;

$activity-tracker-error: #D60040;
$activity-tracker-audit: #694ED6;
$activity-tracker-rules: #008744;
$activity-tracker-toggle-hover: #694ED633;
