@charset "UTF-8";
@use "../projects/gpp/src/variables" as variables;

.dh {
  .mat-mdc-row {
    cursor: pointer;
  }

  .ft-top-grid-action {
    cursor: default;
    height: $dh-grid-header-height;
    line-height: $dh-grid-header-height;
    background: white;
    position: relative;
    display: flex;
    justify-content: space-between;

    .back-to-notes {
      display: flex;
      align-items: center;
    }

    .top-grid-action-section-title {
      width: auto;
      position: relative;
      line-height: 20px;
      font-size: $dh-font-xxl-px;
      margin-left: 18px;

      span {
        width: inherit;
        font-family: $dh-font-family;
        @include dh-base-font-label(14px !important, 600, variables.$primary, normal, normal, 1.71, left);

        &.count {
          width: inherit;
          height: 16px;
          font-family: $dh-font-family;
          @include dh-base-font-label(12px !important, normal, $total-count !important, normal, normal, 1.33, left);

        }
      }

      .dh-refresh-grid-text {
        font-size: $dh-font-l-px;
        color: variables.$C100;
        display: inherit;
        float: right;
        font-weight: normal;
        .dh-refresh-grid-text-date{
          color: variables.$C100;
          font-weight: normal;
          margin-left: 6px;
        }
        .last-update-separator{
          color: variables.$C100;
          padding: 0 3px 0 3px;
          position: relative;
          top: -1px;
        }
      }

      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;

      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;

      /* Support for IE. */
      font-feature-settings: 'liga';


    }

    .paymnet-screen {
      display: inline-block;
      line-height: normal;
    }

    .pull-left {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 3px;
      margin-right: 50px;
      overflow: hidden;
    }

    .pull-right {
      white-space: nowrap;
      display: flex;
      margin-left: auto;

      .dh-btn-group {
        .dh-dropdown-button {

          &.open {
            button {
              @include dh-box-shadow(0px, 0px, 0px, 1px, #694ed6);
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }

    .dh-payment-type-name {
      width: auto;
      text-align: center;
      height: 25px;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      margin-left: -1px;

      span {
        @include dh-base-font-label(14px !important, 500, $dh-shade-color-form-field-idle-hover, normal, normal, 1.14, center);
        line-height: 22px;
        display: block;
      }
    }
  }

  .ft-grid-container {
    position: relative;
    z-index: 10;
    height: 100%;

    *:focus { //disabled the highlight to all elements
      outline: 0;
    }

  }
}
app-profiles-grid, app-rules-grid {
  width: 100%;
}
