@charset "UTF-8";
@use "../projects/gpp/src/variables" as variables;

.dh {
  .breadcrumb-cont {
    top: 5px;
    font-size: $dh-font-xxl-px;
    color: variables.$C100;
    .breadcrumbs {
      cursor: pointer;
      padding-left: 9px;
      text-decoration: none;
      color: inherit;
      word-spacing: 5px;
    }

    .breadcrumbs-last,
    .breadcrumbs-separator {
      padding-left: 5px;
      cursor: default;

    }
  }
}
