@charset "UTF-8";
.dh.dh-print-page {
  body {
    overflow: visible !important;
    width: 100% !important;
    padding-left: 1cm !important;
    padding-right: 1cm !important;

    #detailsBlock {

      .printableFieldSet .dh-single-panel .panel-content .row .field {
        margin-bottom: 21px;
      }
    }

    #notesBlock {

      .printableNote {
        border: 1px solid $dh-shade-color-48;
        padding-left: 10px;
        line-height: 18px;

        .noteField {
          display: block;
          font-weight: normal;
        }

        &:nth-child(even) {
          background: #ffffff;
        }

        &:nth-child(odd) {
          background: #DDDDDD;
        }
      }
    }

    //#customFieldsBlock {
    //  margin-top: 20px;
    //
    //  .customField {
    //    float: left;
    //    width: 180px;
    //    margin-right: 30px;
    //    padding-left: 10px;
    //    line-height: 20px;
    //    white-space: nowrap;
    //    overflow: hidden;
    //    color: #5A6870;
    //
    //    .customFieldField {
    //      display: block;
    //      font-size: 14px;
    //    }
    //  }
    //}

    //all titles
    .printableFieldSet .dh-single-panel .panel-header-expanded,
    .printableFieldSet .dh-single-panel .panel-header-collapsed,
    .blockTitle {
      font-size: 13px;
      color: #5A6870;
      font-weight: bold;
      border-bottom: 1px solid gray;
      display: block;
      margin-bottom: 10px;
    }

    .printWatermark {
      position: absolute;
      right: 0;
      left: 0;
      font-size: 62px;
      text-align: center;
      -ms-transform: rotate(-17deg); /* IE 9 */
      -webkit-transform: rotate(-17deg); /* Safari */
      transform: rotate(-17deg); /* Standard syntax */
      opacity: 0.2;
    }
  }

  @page {
    @bottom-right {
      content: "Page " counter(page) " of " counter(pages);
    }
  }
}
