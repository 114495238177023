@charset "UTF-8";
@use './projects/gpp/src/variables' as variables;

$text-color: variables.$C100;

.dh {

  fieldset {
    position: relative;
  }

  fieldset[disabled] {
    &:after {
      content: ' ';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    md-autocomplete,
    .dh-select,
    .dh-drill-down,
    .dh-date-time-picker,
    input,
    md-autocomplete input:active,
    md-autocomplete input:focus,
    input:active,
    input:focus {
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-15, "inset");
      background-color: $dh-shade-color-15;
      cursor: not-allowed !important;
      color: $text-color !important;
    }

    .btn:not(.dropdown-toggle) {
      cursor: not-allowed;
      color: $dh-shade-color-75 !important;

      &:hover,
      &:focus,
      &:active {
        background: none;
        box-shadow: none;
      }
    }

    .dh-select button{
      opacity: 1;
      color: $text-color !important;
    }

    .dh-select button span,
    .dh-date-time-picker:after,
    .dh-date-time-picker > input {
      color: $text-color !important;
      z-index: 1;
    }

    input[type="checkbox"],
    input[type="radio"] {
      cursor: not-allowed;
      + label {
        color: $text-color;
      }
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  .dh-container {
    @include dh-box-shadow(0px, 2px, 2px, 0px, rgba(0, 0, 0, 0.25));

    &.flat-border {
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-48,'inset');
    }

    .row {
      > div {
        margin-top: $dh-space-between-lines;

        > * {
          width: $dh-component-stretch-limit !important;
        }
      }
    }

    .dh-container-title {
      position: relative;
      cursor: pointer;
      height: $dh-single-panel-title-height;
      line-height: $dh-single-panel-title-height;
      margin-bottom: -13px;
      overflow: hidden;
      z-index: 1;
      @include dh-base-font($dh-font-xxxxxl-px,variables.$C100,$dh-font-family);

      &:before {
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: '';
        display: inline-block;
        padding: 3px;
        transform: rotate(224deg);
        vertical-align: middle;
        margin-left: 20px;
        margin-right: 8px;
      }
      }

    .dh-container-content {
      padding: 0 $dh-container-padding $dh-container-padding;

    }

    &.collapsed {
      height: $dh-single-panel-title-height;

      @include dh-box-shadow(0px, 1px, 1px, 0px, rgba(0, 0, 0, 0.25));

      .dh-container-title {
        color: variables.$C100;

        &:before {
          border-style: solid;
          border-width: 0 2px 2px 0;
          content: '';
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          vertical-align: middle;
          margin-left: 20px;
        }

        &:hover {
          //color: $dh-main-brand-color;
          @include dh-box-shadow(0px, 1px, 1px, 0px, rgba(0, 0, 0, 0.15));
        }
      }
    }
  }

  .dh-form-component-header {
    display: block;
    font-weight: normal;
    margin-bottom: 4px;
    @include dh-ellipsis();
    @include dh-base-font($dh-font-l-px,$text-color,$dh-font-family);
  }

  .dh-form-field-value {
    @include dh-ellipsis();
    margin-bottom: 0;
    height: $dh-component-height;
    @include dh-base-font-label($dh-font-xxl-px !important, normal, $text-color, normal, normal, $dh-component-height, left);
  }

  .dh-virtual-list-field-header {
    @include dh-ellipsis-multi();
    margin-bottom: 0;
    @include dh-base-font-label($dh-font-xxxxxxl-px !important, bold, $text-color, normal, normal, $dh-component-height, left);
  }

  .dh-virtual-list-field-value {
    @include dh-ellipsis-multi();
    margin-bottom: 0;
    @include dh-base-font-label($dh-font-xxxxxl-px !important, bold, $text-color, normal, normal, $dh-component-height, left);

  }
  .dh-virtual-list-field-action{
    font-weight: bold;
    @include dh-ellipsis-multi();
    margin-bottom: 0;
    @include dh-base-font($dh-font-xxl-px,$text-color,$dh-font-family);


  }
.dh-virtual-list-field-value-faded{
  width: 90px;
  border-radius: 12px;
  background-color: variables.$primary;
  border: solid 1px variables.$primary;
  text-align: center;
  margin-top:4px;
}
  .dh-virtual-list-field-value-faded span{
    width: 29.6px;
    height: 16px;
   @include dh-base-font-label(14px !important, 500, $primary-button-disable-bg-color, normal,normal,1.14,center);

  }
  .dh-select {

    &.ng-invalid:not(.ng-dirty):not(.ng-not-empty):not(.ng-valid-required):not(.ng-not-empty-add) { //due to a defect in bootstrap select
      button,
      button:hover {
        @include dh-box-shadow(0px, -1px, 0px, 0px, $invalid, "inset");
      }
    }

    button {
      border: 0;
      height: $dh-component-height;
      @include dh-border-radius();
      padding: 0 25px 0 3.25px;
      outline: 0 !important;
      @include dh-base-font($dh-font-xxl-px, $text-color,$dh-font-family);
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-48, "inset");
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $text-color;
        background-color: transparent !important;
        @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-48, "inset");
      }

      &.dropdown-toggle:focus {
        outline: 0 !important;
        border: 0;
        box-shadow: inset 0px -1px 0px 0px $dh-info-highlight-active-color !important;
      }

      &:after {
        transition: all 0.1s ease-in-out;
        font-family: "Material Icons";
        content: $dh-select-menu-closed-icon;
        font-weight: 600;
        font-size: $dh-font-xxl-px;
        line-height: $dh-component-height;
        color: $text-color;
        position: absolute;
        top: 0;
        right: 3px;
      }

      .caret { //don't display default arrow
        display: none;
      }

      span {
        color: variables.$C100;
        font-size: $dh-font-xxl-px;
      }
    }

    &.open {
      button {
        color: $dh-shade-color-100;
        padding-left: 12px;
        background-color: transparent !important;
        box-shadow: none !important;

        &:after {
          content: $dh-select-menu-open-icon;
          top: 0;
        }

        &:hover,
        &:focus {
          color: $dh-shade-color-100;
        }

        span {
          position: relative;
          top: 4px;
        }
      }

      .dropdown-menu {
        overflow-x: hidden;
        border: 0;
        margin-top: 0;
        max-width: 100%;
        padding: 10px 0 1px 0;

        .selected {
          display: none;
        }

        li {
          &:focus,
          a:focus {
            outline: 0 !important;
          }

          a {
            padding-left: 12px;

            &:hover,
            &:focus {
              background-color: $dh-shade-color-25;
            }

            span {
              font-size: $dh-font-xxl-px;
              color: variables.$C100;
              //display: block;
              width: 108%;
              @include dh-ellipsis();
            }
          }
        }
      }
    }
  }

  .inputs-wrapper {
    display: inline-block;
  }

  .dh-input-wrapper {
    display: block;
  }

  .required_input-label:before {
    content: "*";
    color: red;
    position: relative;
    font-size: $dh-font-xxxl-px;
    float: left;
    margin-right: 3px;
    line-height: $dh-font-xxxl-px;
  }


  .dh-input-field {
    height: $dh-component-height;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    @include dh-base-font(14px, variables.$C100, $dh-font-family);
    @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-form-field-idle, "inset");
    padding: 0 0 0 3.25px;

    transition-property: box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &:focus {
      border: 0;
      outline: none;
      box-shadow: inset 0px -1px 0px 0px $dh-info-highlight-active-color !important;
    }

    &:active {
      border: 0;
      outline: none;
      @include dh-box-shadow(0px, -2px, 0px, 0px, $dh-info-highlight-active-color, "inset");
    }

    &.ng-invalid.ng-touched {
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-alert-highlight-color, "inset");
    }

    &[disabled],
    &.disabled {
      cursor: not-allowed;
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-30, "inset");
      color: $dh-shade-color-100;
      background-color: $dh-shade-color-15;
      font-size: $dh-font-xxl-px;
    }

    &[readonly],
    &.readonly {
      @include dh-box-shadow(0);
      background-color: transparent;
      font-size: $dh-font-xxl-px;
    }

    &.field-changed {
       background-color: $dh-changed-field-highlight-color;
    }
  }

  .dh-drill-down {
    //drill-downs are based  on dh-input-field and dh-flat-button
    height: $dh-component-height;
    position: relative;

    input {
      width: 100%;
      padding-right: 28px;

      &[disabled],
      &.disabled {
        cursor: not-allowed;
        background-color: $dh-shade-color-15;
      }

      &[readonly] {
        @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-48, "inset");
      }

      &.ng-invalid.ng-touched {
        @include dh-box-shadow(0px, -1px, 0px, 0px, $invalid, "inset");
      }
    }

    > button {
      position: absolute;
      line-height: 21px;
      padding: 0 5px;
      right: 0;
      top: 0;
      height: 18px;
      cursor: pointer;

      &:hover {
        background-color: $dh-shade-color-25;
      }

      &[disabled],
      &.disabled {
        cursor: not-allowed;
        background-color: transparent;

        &:hover {
          background-color: transparent;
      }
      }

      i.fa {
        margin: 0;
      }
    }
  }

  .dh-drill-down-container {
    &.field-changed {
      input {
        background-color: $dh-changed-field-highlight-color;
      }
    }
  }

  dh-date-time-picker {
  .dh-date-time-picker {
    height: $dh-component-height;
    line-height: $dh-component-height;
    position: relative;

    input {
      width: 100%;
      padding-right: 28px;
      cursor: pointer;
      background: transparent;
      z-index: 1;

      &:active,
      &:focus {
        background: transparent;
      }
    }

    .dh-date-time-picker-icon {
      position: absolute;
      top: 4px;
      right: 3px;
    }

    &.picker-disabled {
      input {
        color: $dh-shade-color-100;
        cursor: not-allowed;
        background-color: $dh-shade-color-15;
      }

      &:after {
        color: $dh-shade-color-100;
        z-index: 1;
        cursor: not-allowed;
      }
    }

      //overwrite original styles
      .datepicker {
        .day.disabled,
        .month.disabled,
        .year.disabled {
          color: $dh-shade-color-48;
  }
      }

      .timepicker .timepicker-picker {
        table td,
        table td span {
          padding: 0;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    &.field-changed {
      input {
        background-color: $dh-changed-field-highlight-color !important;
      }
    }

    &[data-mode="time"] .dh-date-time-picker:after {
      content: '\f017';
    }
  }

  .dh-text-area {
    width: 100%;
    resize: none;
    border: 1px solid $dh-shade-color-30;
    padding: 3.25px;
    transition: all 0.2s ease-in-out;
    font-size: $dh-font-xxl-px;

    &.ng-invalid.ng-touched {
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-main-brand-color, "inset");
    }

    &:focus {
      outline: 0;
      @include dh-box-shadow(0px, -2px, 0px, 0px, $dh-info-highlight-active-color, "inset");
    }

    &:disabled {
      background-color: $dh-shade-color-8;
      color: $dh-shade-color-48;
      cursor: default;
    }


  }

  .dh-check-box-wrapper {
    position: relative;
    display: inline-block;
    height: $dh-check-box-size;
    width: $dh-check-box-size;
    color: $dh-check-box-color;
    font-size: $dh-font-xxl-px ;
    line-height: $dh-check-box-size;

    label {
      margin: 0;
      font-weight: normal;
      height: 100%;
    }

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      margin: 0;

      &.disabled,
      &[disabled] {
        cursor: not-allowed !important;

        + label {
          color: $dh-shade-color-48;
        }
      }
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label,
    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
      &:before {
        font-family: FontAwesome;
        color: inherit;
        font-size: inherit;
        vertical-align: middle;
      }
    }

    input[type="checkbox"] + label {
      &:before {
        content: '\f096';
      }
    }

    input[type="checkbox"]:focus + label {
      &:before {
        color: $dh-info-highlight-active-color;
        //font-weight: 500;
        font-weight: bold;
      }
    }

    input[type="checkbox"]:checked + label {
      &:before {
        content: '\f046';
      }
    }
    md-checkbox {
     .md-icon {
        width: 20px !important;
        height: 20px !important;
        border-radius: 2px;
      }
    }
    md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
      background-color : variables.$primary;
    }
    md-checkbox.md-checked .md-icon:after {
      top: -0.9999px !important;
    }
    md-checkbox .md-label {
      box-sizing: unset;
      position: unset;
      display: unset;
      white-space: nowrap !important;
    }
    .md-checkbox.md-default-theme.md-checked .md-ink-ripple, md-checkbox.md-checked .md-ink-ripple {
      color: unset;
    }
    md-checkbox.md-default-theme:not(.md-checked) .md-icon, md-checkbox:not(.md-checked) .md-icon {
      border-color: #717171;
    }
  }

  .dh-check-box-label {
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    color: variables.$C100;

    &.disabled,
    &[disabled] {
      cursor: not-allowed !important;
      color: $dh-shade-color-48;
    }
  }

  .dh-form-error,
  .dh-help-block {
    position: relative;
    height: 0;
    display: block;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;

  }

  .dh-form-error {
    color: $dh-alert-highlight-color;
  }

  .dh-help-block {
    color: variables.$C100;
  }

  .dh-search-filter {
    position: relative;
    height: $dh-component-height;

    input {
      font-weight: 600;
      position: relative;
      width: 100%;
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-info-highlight-color, "inset");

      &:focus,
      &:active {
        @include dh-box-shadow(0px, -2px, 0px, 0px, $dh-info-highlight-active-color, "inset");
      }
    }
  }

  .dh-virtual-list-container {
    position: relative;
    width: 100%;
    height: 100%;

    .dh-virtual-list-header {
      height: $dh-virtual-list-header-height;
      line-height: $dh-virtual-list-header-height;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
      padding-left: 6px;
      background-color: $dh-shade-color-8;
      color: $dh-info-highlight-color;
      @include dh-ellipsis();
      z-index: 1;
      position: relative;
    }

    md-virtual-repeat-container {
      height: 100%;
    }

    .dh-virtual-list-section {
      position: relative;
      padding-left: 0;

      li {
        list-style: none;
        width: 100%;
        display: block;
      }

      li > .group-spacer {
        position: relative;
        width: 100%;
        list-style: none;
        cursor: pointer;
        padding: 0 18px 0 10px;
        border-bottom: 1px solid #e1e4e5;

        &:hover {
          .group-spacer-indicator {
            box-shadow: 0 0 3px 2px $dh-shade-color-30;
          }
        }

        .group-spacer-line {
          position: absolute;
          top: 40%;
          margin-top: 1px; //to compensate the border thickness
          left: 0;
          right: 0;
          z-index: 0;
          background: $dh-shade-color-30;
          height: 5px;
        }

        .group-spacer-indicator {
          -webkit-transition: all 0.3s ease-out;
          display: inline-block;
          position: relative;
          border: 1px solid $dh-shade-color-gray-brand;
          border-radius: 50px; //enough to get it round
          color: $dh-shade-color-100;
          width: $dh-virtual-list-grouper-diameter;
          height: $dh-virtual-list-grouper-diameter;
          line-height: $dh-virtual-list-grouper-diameter;
          font-size: $dh-virtual-list-grouper-font-size;
          text-align: center;
          background: $dh-background-color;
          margin-left: 10px;
          z-index: 1;
        }
      }

      li.header {
        padding-top: $dh-virtual-list-inner-header-height;

        &:before {
          content: attr(data-header);
          width: 100%;
          position: absolute;
          margin-top: -$dh-virtual-list-inner-header-height;
          height: $dh-virtual-list-inner-header-height;
          line-height: $dh-virtual-list-inner-header-height;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
          padding-left: 6px;
          background-color: $dh-virtual-list-inner-background-color;
          color: $dh-virtual-list-inner-text-color;
          @include dh-ellipsis();
        }
      }
    }
  }

  md-autocomplete {
    width: auto; //set to 100% when inserted to rules
    color: $dh-shade-color-100;
    height: $dh-component-height;
    background: transparent;

    :first-child {
      box-shadow: none;
      height: $dh-component-height;
      line-height: $dh-component-height;

      input {
        @extend .dh-input-field;
      }

      button {
        display: none;
      }
    }
  }

  ul.md-autocomplete-suggestions {
    background: $dh-shade-color-8;

    li {
      height: 30px;
      line-height: 30px;

      &:hover {
        background: $dh-shade-color-25;
      }

      &.selected {
        background: $dh-shade-color-25;
      }

      span {
        color: variables.$C100;
        .highlight {
          color: $dh-info-highlight-active-color;
        }
      }
    }

  }

  .drilldown-btn {
    height: 21px !important;
    width: 21px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 14px
  }
}

.dropdown-menu > .active > a, .dh .dh-dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: #D9D3F5;
}
