@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/NotoSans-Light.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
