@use "@material/typography" with (
  $font-family: unquote("'Noto Sans'")
);
@use 'sass:map';
@use '@angular/material' as mat;
@use "./variables" as variables;
@use '@finastra/angular-theme' as fds;
@use '@finastra/angular-theme/base';
@import "./font";
@import "@fgpp-ui/components/styles";

// Core variables and mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
// Core CSS
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
// Components
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
// Components w/ JavaScript
//@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";
@import "../style/ft";

@include fds.uxg-core();
@include fds.uxg-material-theme(fds.$light-theme);

:root {
  @include mat.list-density(-2);
  --mdc-typography-headline1-font-family: "Noto Sans";
  --mdc-typography-headline2-font-family: "Noto Sans";
  --mdc-typography-headline3-font-family: "Noto Sans";
  --mdc-typography-headline4-font-family: "Noto Sans";
  --mdc-typography-headline5-font-family: "Noto Sans";
  --mdc-typography-headline6-font-family: "Noto Sans";
  --mdc-typography-subtitle1-font-family: "Noto Sans";
  --mdc-typography-subtitle2-font-family: "Noto Sans";
  --mdc-typography-body1-font-family: "Noto Sans";
  --mdc-typography-body2-font-family: "Noto Sans";
  --mdc-typography-caption-font-family: "Noto Sans";
  --mdc-typography-button-font-family: "Noto Sans";
  --fds-body-1-font-family: "Noto Sans";
  --fds-body-2-font-family: "Noto Sans";
  --fds-body-3-font-family: "Noto Sans";
  --fds-body-4-font-family: "Noto Sans";
  --fds-button-1-font-family: "Noto Sans";
  --fds-button-2-font-family: "Noto Sans";
  --fds-button-3-font-family: "Noto Sans";
  --fds-caption-font-family: "Noto Sans";
  --fds-font-family: "Noto Sans";
  --fds-overline-font-family: "Noto Sans";
  --fds-subtitle-1-font-family: "Noto Sans";
  --fds-subtitle-2-font-family: "Noto Sans";
  --fds-subtitle-3-font-family: "Noto Sans";
  --fds-subtitle-4-font-family: "Noto Sans";
  --mdc-typography-headline1-letter-spacing: normal;
  --mdc-typography-headline2-letter-spacing: normal;
  --mdc-typography-headline3-letter-spacing: normal;
  --mdc-typography-headline4-letter-spacing: normal;
  --mdc-typography-headline5-letter-spacing: normal;
  --mdc-typography-headline6-spacing: normal;
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-body2-letter-spacing: normal;
  --mdc-typography-subtitle1-letter-spacing: normal;
  --mdc-typography-subtitle2-letter-spacing: normal;
  --mdc-typography-caption-letter-spacing: normal;
  --mdc-typography-button-letter-spacing: normal;
  --fds-body-1-font-weight: 400;
  --fds-body-2-font-weight: 400;
}

.control-layout-wrapper {
  .control-layout {
    min-height: 0;

    .space-after, .space-before {
      min-height: 0 !important;
    }
  }

}

[hidden] {
  display: none !important;
}

.clickable {
  cursor: pointer;
}

// Bootstrap style override
.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 0;

  label {
    padding-left: 0;
  }
}

// attachment dialog override
.dialog-attachment-view {
  mat-dialog-container {
    padding: 0;
    overflow-x: hidden;
  }
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label:not(.mat-empty):not(.mat-form-field-empty) {
  font-size: 18.5px;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus~.mdc-checkbox__ripple {
  opacity: 0 !important;
}

.mat-mdc-form-field .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding-left: 0;
  padding-right: 2px;
  line-height: 1;
}

.mat-mdc-form-field {
  &.mat-form-field-appearance-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: none;
    }
    .mat-mdc-form-field-focus-overlay {
      background: none;
    }
  }
}

@mixin focusStyle() {
  outline: 2px solid #2d2d2d !important;
  outline-offset: -2px;
}

@mixin focus() {
  &:focus-visible {
    @include focusStyle();
  }
}


.main-navigation-tab,
.list-item-button,
.hide-panel,
.ft-left-side-toggle,
.fn-ui-notification-icon button,
.user-menu-container .user-menu-button,
.lib-table .mat-sort-header-container,
.facet-filtering-bar-remove-all,
.add-condition,
.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-menu-item,
.favorite-button,
.mat-tab-label,
.mat-tab-link,
.mat-button-toggle-button {
  @include focus();
}

.btn-group {
  display: inline-block;
}

.fgpp-btn {
  &.mat-mdc-button {
    //border: 2px solid transparent;
  }

  &.mat-mdc-unelevated-button {
    //border: 2px solid transparent;
  }
}

.mat-mdc-paginator {
  height: 50px;

  .mat-mdc-paginator-container {
    height: 50px;
    min-height: 50px;

    .mat-mdc-paginator-page-size {
      height: 50px;
      align-items: center;
    }
  }
}


/* Text styles */
.fa-headline {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: variables.$C100;
}

.fa-title {
  font-size: 18px; //not as in Zeplin but approved by UX designer
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: variables.$C100;
  text-wrap: nowrap;
}

.fa-subheader {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: variables.$C100;
}

.fa-body-bold {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: variables.$C100;
}

.fa-body {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: variables.$C100;
}

.fa-caption {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: variables.$C75;
}

.filter-alt-icon {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: variables.$C100;
  -webkit-text-fill-color: transparent;

  &.selected {
    -webkit-text-stroke-color: variables.$V100;
    -webkit-text-fill-color: #f1eefe;
  }
}

.icon-selected {
  color: variables.$V100;
}

.faded-row {
  background: #FAFAFA;
  opacity: 0.3;
}

.refine-container .reset-icon .mdi {
  color: variables.$C75 !important;
}

.scrollable-content {
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar-track {
    overflow: auto;
    border-radius: 10px;
    background-color: transparent;
    height: 30px;
  }

  &::-webkit-scrollbar {
    width: variables.$scrollbar-width;
    height: variables.$scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 17px;
    background-color: #cecece;
    border: 9px solid white;
    min-height: 130px;
  }
}


.mat-mdc-chip.mat-mdc-standard-chip {
  height: 32px;
  font-size: 14px;
}


button.mat-mdc-menu-item,
.mat-mdc-option:not(.mat-option-disabled) {
  &:focus:not(.mat-mdc-option) {
    background-color: transparent;
    border: none !important;
  }

  &:active,
  &.mat-mdc-selected {
    background-color: transparent !important;
  }

  &:hover {
    background-color: variables.$V05 !important;
  }

  &.selected,
  &.mat-mdc-option-active,
  &:focus-visible {
    background-color: variables.$V10 !important;
  }

  .mat-ripple {
    opacity: 0;
  }
}

.mat-button-toggle-group {
  &.mat-button-toggle-group-appearance-standard {
    margin-bottom: 1px;
    .mat-button-toggle {
      &.mat-button-toggle-checked {
        .mat-button-toggle-button {
          border-color: transparent;
        }
      }
    }
  }
}


.mat-mdc-menu-panel div.mat-mdc-menu-content {
  padding: 0;
}

.cdk-overlay-pane {
  justify-content: center;
}

.resize-handler {
  cursor: row-resize;
  position: fixed;
}

.bottom-resize-handler {
  height: 20px;
  width: 100%;
}

.drop-here {
  width: 100px;
  height: 100px;
  border: solid 1px red;
}

.hide {
  display: none !important;
}

.full-height {
  height: 100%;
}

.pre-wrap-line {
  white-space: pre-wrap
}

app-activity-tracker {
  display: block;
  height: 100%;
}

.mat-mdc-tooltip {
  white-space: pre-wrap;
}

.mat-mdc-form-field {
  &.dh-form-field {
    width: 100%;

    &.mat-form-field-appearance-fill {
      .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: rgb(65, 65, 65);
        font-weight: 400;
      }

      .mat-mdc-form-field-focus-overlay {
        background: white;
      }

      .mdc-text-field--disabled .mdc-floating-label {
        color: rgba(0, 0, 0, 0.54);
      }

      .mdc-text-field--disabled .mdc-line-ripple::before {
        border-bottom-color: rgba(0, 0, 0, 0.3);
        border-bottom-style: dashed;
      }

      .mat-mdc-floating-label {
        &.mdc-floating-label{
          font-size: 18.5px;
          font-weight: 400;
          width: 100%;
        }
      }

      .mat-mdc-text-field-wrapper {
        background: white;
        padding: 0;
      }

      .mat-mdc-form-field-input-control {
        font-weight: 400;
      }

      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: white;
      }

      .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
        border-bottom-color: rgba(0, 0, 0, 0.42);
      }

      &.mat-form-field-invalid {
        .mat-mdc-form-field-flex {
          background-color: rgba(207, 3, 12, 0.04);
        }
      }

      .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }

      .mat-mdc-form-field-hint-wrapper {
        .mat-mdc-form-field-hint {
          color: #0000008a;
        }
      }

      .mat-mdc-form-field-icon-suffix span {
        padding: 0;
      }

      .mat-mdc-form-field-error {
        display: flex;
      }

      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 24px;
        padding-bottom: 0;
      }

    }
  }
}

.select-dropdown {
  &.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
  }

  &.mdc-menu-surface.mat-mdc-autocomplete-panel {
    padding: 0;
  }
}

// bootstrap removal
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.mat-mdc-button:hover {
  .mat-mdc-button-persistent-ripple::before {
    opacity: 0.14;
  }
}
