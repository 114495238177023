@charset "UTF-8";
@use '@angular/cdk' as cdk;
@use '../src/variables' as variables;

//$focused: $primary;
$invalid: #e40046;

//Colors - remove when everything becomes finastra colors
$dh-background-color: rgba(255, 255, 255, 1);
$dh-shade-color-8: rgba(247, 248, 248, 1);
$dh-shade-color-15: rgba(240, 241, 242, 1);
$dh-shade-color-25: rgba(230, 232, 234, 1);
$dh-shade-color-30: rgba(225, 228, 229, 1);
$dh-shade-color-48: rgba(207, 212, 214, 1);
$dh-shade-color-75: rgba(181, 187, 191, 1);
$dh-shade-color-gray-brand: rgba(105, 105, 105, 1);
$dh-shade-color-100: rgba(42, 42, 42, 1);
$dh-main-brand-color: rgb(105, 78, 214);

$dh-alert-highlight-color: rgba(237, 28, 38, 1);
$dh-alert-highlight-active-color: rgba(207, 3, 12, 1);


$dh-info-highlight-color: rgba(160, 160, 160, 1);
$dh-info-highlight-active-color: rgb(105, 78, 214);

$dh-warning-highlight-active-color: rgba(222, 179, 0, 1);

$dh-shade-color-form-field-idle: rgb(113, 113, 113);
$dh-shade-color-form-field-idle-hover: rgb(65, 65, 65);

$primary-button-disable-bg-color:#fafafa;

$dh-font-family: 'Noto Sans', sans-serif, "Material Design Icons";
$dh-letter-spacing: normal;
$dh-font-style-label:normal;
$dh-font-stretch-label: normal;
$dh-font-weight-label:normal;
$dh-line-height-label:1.14;
$dh-text-align: left;
$dh-font-s-px: 10px;
$dh-font-l-px: 12px;
$dh-font-xxl-px: 14px;
$dh-font-xxxl-px: 16px;
$dh-font-xxxxxl-px: 18px;
$dh-font-xxxxxxl-px: 20px;
//Generals
$dh-main-header-total-height: 50px;
$dh-left-container-header-height: 40px;
//$dh-grid-inner-height-: 50px;
$dh-footer-height: 45px;
$dh-left-container-expand-width: 287px;
$dh-left-container-collapsed-width: 28px;

$dh-nav-panel-height: 50px;
$dh-grid-header-height: 55px;
$dh-single-panel-title-height: 40px;

//Checkbox
$dh-check-box-size: 16px;
$dh-check-box-color: $dh-shade-color-100;

//Forms
$dh-container-padding: 15px;
$dh-space-between-lines: 21px;
$dh-component-stretch-limit: 100%;
$dh-component-height: 21px;

//Select
$dh-select-menu-closed-icon: "expand_more";
$dh-select-menu-open-icon: "expand_less";

//virtual-list
$dh-virtual-list-header-height: 39px;
$dh-virtual-list-inner-header-height: 20px;
$dh-virtual-list-inner-background-color: $dh-info-highlight-color;
$dh-virtual-list-inner-text-color: $dh-background-color;
$dh-virtual-list-grouper-diameter: 30px;
$dh-virtual-list-grouper-font-size: $dh-font-s-px;

//si list item
$dh-si-list-item-height: 62px;


/**
*  dh-base-font
*  1st parameter (mandatory): font-size
*  2st parameter (optional, default: main text color): font-color
**/
@mixin dh-base-font($font-size, $font-color: $dh-shade-color-100,$font-family:$dh-font-family) {
  font-family: $font-family;
  letter-spacing: $dh-letter-spacing;
  font-size: $font-size;
  color: $font-color;
}

@mixin dh-base-font-label($font-size, $font-weight:$dh-font-weight-label, $font-color: $dh-shade-color-100, $font-style: $dh-font-style-label,
$font-stretch: $dh-font-stretch-label,$line-height: $dh-line-height-label,$text-align: $dh-text-align) {
  letter-spacing: $dh-letter-spacing;
  font-size: $font-size;
  color: $font-color;
  font-style:$font-style;
  font-weight:$font-weight;
  font-stretch: $font-stretch;
  line-height:$line-height;
  text-align: $text-align;
}

/**
*  dh-box-shadow may get all relevant fields as parameters but it has defaults according to style guide
**/
@mixin dh-box-shadow($horizontal: 0, $vertical: 0, $blur: 0, $spread: 0, $color: rgba(0,0,0,0), $inset: "") {
  box-shadow: #{$inset} $horizontal $vertical $blur $spread $color;
  -webkit-box-shadow: #{$inset} $horizontal $vertical $blur $spread $color;
  -moz-box-shadow: #{$inset} $horizontal $vertical $blur $spread $color;
  -ms-box-shadow:  #{$inset} $horizontal $vertical $blur $spread $color;
}

/**
*  dh-border-radius
**/
@mixin dh-border-radius($border-radius: 0) {
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -ms-border-radius: $border-radius;
}

@mixin dh-prevent-text-selection() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin dh-ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;
}

@mixin dh-ellipsis-multi() {
  white-space: normal;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;
}

//Rules
$dh-conditions-bank-pane-width: $dh-left-container-expand-width - $dh-left-container-collapsed-width;
$rules-conditions-and-or-border-radius: 3px;
$rules-conditions-pane-height: 50px;
$rules-audit-trail-container-height: 450px;
$dh-changed-field-highlight-color: rgba(255, 255, 0, 0.4);

//Message screen
$msg-footer-height: 45px;
$msg-panels-container-height: calc(100% - #{$msg-footer-height});
$total-count: #717171;
$page-content-padding-top: 12px;
