@charset "UTF-8";
@use '../src/variables' as variables;

$panel-expanded-icon: "\f0d7";
$panel-collapsed-icon: "\f0da";


.dh {
  .dh-entity-page {
    .breadcrumb-cont {
      width: 98%;
      margin: auto auto;
      height: $dh-left-container-header-height;
      line-height: $dh-left-container-header-height;
      @include dh-box-shadow(0px, -1px, 0px, 0px, $dh-shade-color-30, "inset");
      overflow: hidden;
    }

    .dh-navigation-pane {
      width: 100%;
      height: $dh-nav-panel-height;
      background: transparent;
      @include dh-box-shadow(0px, 2px, 2px, 0px, rgba(0, 0, 0, 0.25));
      margin-bottom: 0;
    }

    .dh-entity-content {
      height: calc(100% - #{$dh-nav-panel-height} - #{$dh-nav-panel-height} - #{$dh-left-container-header-height});
      overflow: auto;

      &.hideActionButtonsStrip {
        height: calc(100% - #{$dh-nav-panel-height} - #{$dh-left-container-header-height});

        + .dh-entity-bottom-panel {
          display: none;
        }
      }

      .details-form,
      .investigation-form,
      .customFields-form {
        width: 100%;
        padding: 22px 12px 8px 12px;

        .row {
          .field {
            margin-bottom: $dh-space-between-lines;
          }
        }
      }

      .raw-file-content {
        white-space: pre-wrap;
        padding: 20px;
        line-height: 1.6;
      }

      .dh-single-panel {

        &.expanded {
          margin-bottom: 8px;
          @include dh-box-shadow(0px, 2px, 2px, 0px, rgba(0, 0, 0, 0.25));
        }

        .panel-header-expanded,
        .panel-header-collapsed {
          display: block;
          cursor: pointer;
          height: $dh-single-panel-title-height;
          @include dh-base-font-label($dh-font-xxxxxl-px !important, 700, variables.$C100, normal,normal,$dh-single-panel-title-height,left);
          &:before {
            position: relative;
            padding: 3px;
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: '';
            margin-right: 8px;
            margin-left: 6px;
            display: inline-block;
            vertical-align: middle;

          }
        }

        .panel-header-expanded {
          &:before {
            //content: $panel-expanded-icon
            transform: rotate(224deg);
            margin-bottom: 2px;
            font-weight: 700;

          }
        }

        .panel-header-collapsed {
          &:before {
            //content: $panel-collapsed-icon
           transform: rotate(45deg);
            margin-bottom: 4px;
            font-weight: 700;
          }
          &:hover {
            @include dh-box-shadow(0px, 1px, 1px, 0px, rgba(0, 0, 0, 0.15));
          }
        }

      }
    }

    .dh-entity-bottom-panel {
      height: $dh-nav-panel-height;
      float: right;
      padding-right: 12px;
      width: 100%;
      @include dh-box-shadow(0px, 1px, 0px, 0px, $dh-shade-color-30, "inset");
      line-height: $dh-nav-panel-height;

      .dh-entity-action-buttons {
        float: right;
      }
    }

    .investigation-form-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;

      > ft-panel {
        flex: 0 0 auto;
      }

      > app-inner-grids-container{
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
      }

      .dh-investigation-inner-content {
        display: block;
        width: 100%;
        max-height: calc(100% - #{$dh-nav-panel-height});
        margin-top: 5px;

        .ft-grid-container .dh-grid-footer {
          padding: 0;
          @include dh-box-shadow(0, 1px, 0, 1px, $dh-background-color);
        }

        .single-note-page {
          padding: 0 10px;

          app-fn-ui-icon {
            display: inline-block;
          }

          .note-form {
            padding-left: 12px;
            padding-right: 6px;

            .row {
              margin-bottom: 10px;

            }

            .dh-form-component-header {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
.tab-content {
  height: 100%;
}
