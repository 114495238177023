@charset "UTF-8";
@use "../projects/gpp/src/variables" as variables;

.dh-main-external-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .dh-main-left-container-wrapper {
    position: relative;
    float: left;
    width: $dh-left-container-expand-width;
    height: 100%;
    z-index: 500;
    border-top: solid 1px variables.$C10;
    transition: width 0.1s ease-out;

    .dh-main-left-container {
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;

      .ng-hide {
        opacity: 0;
      }

      .ng-hide-remove,
      .ng-hide-add {
        display: block !important;
      }

      .ng-hide-remove {
        transition: opacity ease-in 300ms;
      }

      .ng-hide-add {
        transition: opacity ease-in;

        .dh-navigation-pane {
          position: absolute;
          bottom: 0;
          margin-bottom: 0;
        }
      }

      .dh-taskbar-tree-wrapper {
        overflow: auto;
        width: 100%;
        max-height: calc(100% - #{$dh-left-container-header-height} - #{$dh-footer-height});
        height: calc(100% - #{$dh-left-container-header-height} - #{$dh-footer-height}); //needed in IE10/11 to show the scrollbar
      }

      .dh-taskbar-header {
        height: $dh-left-container-header-height;
        align-items: center;

        .hide-panel{
          display: inline-block;
          box-sizing: border-box !important;
          border: 2px solid transparent;
          margin-left: 12px;
          font-family: inherit;
          margin-top: 9px;

          .dh-hide-panel-text-label {
            float: right;
            margin-top: 4px;
            font-size: $dh-font-l-px;
            margin-left: 7px;
            color: variables.$C100;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
          }
          &:before {
            font-size: $dh-font-xxxxxxl-px;
            color: #000000;
          }
        }
      }

      .dh-taskbar-content {
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .dh-navigation-pane {
        height: $dh-footer-height;
      }
    }

    &.minimized {
      width: $dh-left-container-collapsed-width;
      min-height: 100%;

      .dh-main-left-container {

        .dh-navigation-pane {
          position: absolute;
          bottom: 0;
          height: auto;
          margin-bottom: 0;
        }
      }
    }
  }

  .dh-main-left-container-wrapper + .dh-main-right-container {
    width: calc(100% - #{$dh-left-container-expand-width});
  }

  .dh-main-right-container {
    position: relative;
    float: right;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    transition: width 0.1s ease-out;
    border-left: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    > * {
      background-color: white;
    }


    &.expand {
      width: calc(100% - #{$dh-left-container-collapsed-width});
    }

    app-navigation-result {
      position: absolute;
      height: calc(100% - 12px);
      width: 100%;
      z-index: 925;

      &.closed {
        display: none;
      }
    }

    .dh-main-right-content {
      width: 100%;
      height: 100%;
      // display: flex;
      //TODO Check late if flex is necessary
      display: block;
    }

    .matching-height-upper-grid {
      height: 35%;
    }

    .matching-height-lower-grid {
      height: 65%;
    }


    .dh-main-grid {
      height: calc(100% - #{$dh-grid-header-height});
    }

    &.isStandAlone {
      width: 100%;
    }

    ui-view-ng-upgrade {
      width: 100%;
    }
  }
}
