@charset "UTF-8";
@use "./../src/variables" as variables;

$tree-margin-top: 20px;
$tree-margin-left: 9px;

.dh {

  .ft-tree {
    display: block;
    flex: 1 1 auto;
    height: 0;
    overflow: auto;
    margin-top: $tree-margin-top;
    margin-left: $tree-margin-left;

    > :first-child {
      display: block;
      width: calc(#{$dh-left-container-expand-width} - #{$tree-margin-left} - #{variables.$scrollbar-width});
    }

  }

  .ft-left-side-toggle {
    float: left;
    color: variables.$primary;
    box-sizing: border-box !important;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: $dh-font-xxl-px;
    padding-right: 2px;
    line-height: $dh-left-container-header-height;
    -webkit-transform: scale(0.6,1);
    -moz-transform: scale(0.6,1);
    -ms-transform: scale(0.6,1);
    -o-transform: scale(0.6,1);
    transform: scale(0.6,1);

    &.pull-right {
      text-align: center;
      width: 28px;
      padding-right: 0;
      color:#000000;
    }
  }
}
