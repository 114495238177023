@charset "UTF-8";

html {
  font-size: 16px !important;
}

* {
  font-family: 'Noto Sans', sans-serif, "Material Design Icons";
}

html,
body {
  letter-spacing: normal;
  //font-size: 11rem;
  color: #414141;
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 1024px;
  overflow: hidden;
}

body {
  background: #dadada;
  & > #main-content {
    height: 100%;
    overflow: hidden;
  }
}

div,
span,
i {
  &:focus {
    outline: 0;
  }
}

.dh-main-container {
  height: calc(100% - #{$dh-main-header-total-height});
  width: 100%;
  position: absolute;

  &.isStandAlone {
    height: 100%;
  }

  .dh-main-content {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.dh-popout-container-full-view {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
}

.dh {
  *.ng-animate {
    transition: 0s;
  }

  .full {
    height: 100%;
    width: 100%;
  }

  .ft-ellipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    overflow: hidden;
  }
}
